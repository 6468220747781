import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import React, {useState,useEffect} from 'react';
import {Alert, Image,  StyleSheet, Text,TextInput, View, TouchableOpacity } from 'react-native';
import logo from './assets/noor.png';
import * as AuthSession from 'expo-auth-session';
import jwtDecode from 'jwt-decode';
import * as WebBrowser from "expo-web-browser";
import VendorPostOffer from './VendorPostOffer';
//import CurrentOffers from './CurrentOffers';
//import UserList from './UserList';
import DeceasedPerson from './DeceasedPerson';
import DeceasedPersonDuas from './DeceasedPersonDuas';
import DuasBeforeBurial from './DuasBeforeBurial';
import QuranMarhoom from './QuranMarhoom';
import DuasForDepartedHome from './DuasForDepartedHome';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { Button } from 'react-native';
import {ImageBackground} from 'react-native';
import { Platform} from 'react-native';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { Constants } from 'expo-constants';
import { Ionicons } from '@expo/vector-icons';
import * as Location from "expo-location";
//const nodeAddress = 'http://192.168.0.197:3000'
//const nodeAddress = 'http://34.89.25.113:3000'
//const nodeAddress = 'https://34.89.25.113:8000'
const nodeAddress = "https://3orbnk2lwqhj6ustsw76gbqmx40nhtbr.lambda-url.eu-west-2.on.aws"


WebBrowser.maybeCompleteAuthSession(); //completes but still shows the browser window

const VendorUser = "Vendor";
const CustomerUser = "Customer";
const auth0Domain = 'https://dev-2o8m1tra.eu.auth0.com';
const auth0ClientId = 'Y1eut5S6EUb85NZBn5vD5hC3LyVkmV9z';
const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

//const GOOGLE_PLACES_API_KEY='AIzaSyA_vleE5UGcr2uBjUF2zkP060njrdviPkg';

const GOOGLE_PLACES_API_KEY='AIzaSyARVteaUBAeOKLC-vsBGFdd3Ll4pOFd1SI';

const useProxy = Platform.select({ web: false, default: true });
const redirectUri = AuthSession.makeRedirectUri({ useProxy });
//const redirectUrl = AuthSession.getRedirectUrl();

function HomeScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Button
        onPress={() => navigation.navigate('Notifications')}
        title="Go to notifications"
      />
    </View>
  );
}
function NotificationsScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Button onPress={() => navigation.goBack()} title="Go back home" />
    </View>
  );
}



export default function App() {

const [token, setToken] = useState(null);
const [userType, setUserType] = useState(null);
const [user, setUser] = useState('');
const [name, setName] = useState('');
const [location, setLocation] = useState('');
const [surname, setSurname] = useState('');
const [dateOfDeath, setDateOfDeath] = React.useState(null);
const [dob, setDOB] = React.useState(null);
const [fullnames, setFullNames] = useState('');
const [userDetails, setUserDetails] = useState(null);

const authorizationEndpoint = `${auth0Domain}/authorize`;

const [request, result, promptAsync] = AuthSession.useAuthRequest(
    {
      redirectUri,
      clientId: auth0ClientId,
      // id_token will return a JWT token
      responseType: 'id_token',
      // retrieve the user's profile
      scopes: ['openid', 'profile'],
      extraParams: {
        // ideally, this will be a random value
        nonce: 'nonce',
      },
    },
    { authorizationEndpoint }
  );
  // Retrieve the redirect URL, add this to the callback URL list
  // of your Auth0 application.
  console.log(`Redirect URL: ${redirectUri}`);
  useEffect(() => {
    let isMounted = true;
    if (result && isMounted) {
      if (result.error) {
        Alert.alert(
          'Authentication error',
          result.params.error_description || 'something went wrong'
        );
        return;
      }
      if (result.type === 'success') {
        // Retrieve the JWT token and decode it
        const jwtToken = result.params.id_token;
        console.log('jwtToken: '+JSON.stringify(jwtToken));
         const decoded = jwtDecode(jwtToken);
        console.log('decoded: '+JSON.stringify(decoded));
        const { name } = decoded;
        setName(name);
        console.log('result: '+JSON.stringify(result));
        console.log('name: '+name);
//        let token = result.params.access_token;
//        console.log('token: '+token)
        setToken(jwtToken);

//        const user = decoded;
        setUser(decoded)
        console.log("user: "+decoded.nickname)
        console.log("name: "+decoded.name)
        console.log("sub: "+decoded.sub)
        console.log("email: "+decoded.email)
        isMounted=false;

//       console.log("naviation: "+navigation)

      }
    }
  }, [result]);

function toQueryString(params) {
  return '?' + Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}
function userLogin(pUserType, navigation) {
    setUserType(pUserType);
    console.log (pUserType +' Login entry: '+navigation)
//    _loginWithAuth0(pUserType,navigation);
    promptAsync({ useProxy })

//    _loginWithAuth0Test(pUserType, navigation);
}
async function fetchUserInfo(token) {
  const response = await fetch(`${auth0Domain}/userinfo`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  });

  return await response.json();
}

async function fetchPerson(token) {
  const response = await fetch(`${auth0Domain}/Person`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  });

  return await response.json();
}
/*
function getUserProfile() {

     var webAuth = AuthSession.WebAuth({
        domain:       `${auth0Domain}`,
        clientID:     auth0ClientId
     });
    webAuth.client.userInfo(token, function(err, user) {
        // This method will make a request to the /userinfo endpoint
        // and return the user object, which contains the user's information,
        // similar to the response below.
    });
}
*/

/*
const _loginWithAuth0Test = async(pUserType, navigation) => {
    console.log("_loginWithAuth0Test entry");
            const user = {
                nickname : 'Ts',
                name : 'Tester',
                sub : 'ttt',
                email : 'test@test.com'
            }
           setUser(user);
           console.log("userType: "+pUserType);
           if (navigation !=null) {
                if (pUserType === VendorUser)
                    navigation.navigate('UserInfo', { user:user })
                else
                    navigation.navigate('CurrentOffers', { user:user })
            }
            else
                console.log("navigation is null");

};

*//*

  function showMeals(details, navigation) {
    console.log("showMeals data: "+JSON.stringify(details));
    console.log(JSON.stringify(details.geometry.location));
// showMeals data: {"formatted_address":"Wallington SM6, UK","geometry":{"location":{"lat":51.3626561,"lng":-0.1460505},"viewport":{"northeast":{"lat":51.38367016461506,"lng":-0.1196857396527085},"southwest":{"lat":51.33808156264048,"lng":-0.1595970044992797}}},"name":"SM6"}

//      setLocation(details.geometry.location);
        //following user setup for testing - remove when doing proper testing.
        const user = {
        nickname: 'mirzaam',
        name: 'Tester',
        sub: 'sub',
        email: 'test@mail.com',
        location: details.geometry.location
    };
    setUser(user);
    if (navigation !=null) {
         navigation.navigate('CurrentOffers', { user:user })
    }


  }
*/
function addPerson(navigation) {
    navigation.navigate('DeceasedPerson',{ user:user })
    /*
    if (token==null) {
        userLogin(VendorUser, navigation)
    }
    else {
        // check vendor has user details populated before posting offer
        console.log(user)
        if (user != null && navigation != null) {
            navigation.navigate('VendorPostOffer',{ user:user })
        }
        else {
              Alert.alert("Vendor Details",
                          "Please enter your vendor details before posting an offer");
        }
    }
    */
}



const searchPerson = async(navigation)=>{
    console.log("searchPerson");
    //search database for any person entered already
    // if found then display name and duas recited and outstanding
    const response = await fetch(nodeAddress+'/Person?surname='+ surname+'&fullnames='+fullnames);
    if (response.status >= 200 && response.status <= 299) {
      const userInfo = await response.json();
      console.log(userInfo);
      if (userInfo.length > 0) {
          console.log("Found: "+userInfo[0].surname)
   //       setSurname(userInfo[0].surname);
   //       setFullNames(userInfo[0].fullnames);
          setDateOfDeath(userInfo[0].dateOfDeath);
          setDOB(userInfo[0].dob);
          setUserDetails(userInfo);
      }
      else {
          console.log("Name not found: "+surname)
      }

    } else {
      // Handle errors
      console.log(response.status, response.statusText);
    }

}


function LogoTitle() {
  return (
    <Image
      style={{ width: '100%', height: 50 }}
      source={require('./assets/duas.jpeg')}
    />
  );
}

  return (
   <NavigationContainer>

         <Stack.Navigator initialRouteName="Home">
           <Stack.Screen name="Duas for Departed" component={DuasForDepartedHome} options={{
                                                                                    title: 'Duas for Departed',
                                                                                    headerStyle: {
                                                                                      backgroundColor: '#dedede',

                                                                                    },
                                                                                    headerTitleStyle: {
                                                                                      fontWeight: 'bold',
                                                                                    },
                                                                                    headerRight: () => (
                                                                                      <View style={{ marginRight: 20 }}>
                                                                                          <Button
                                                                                            onPress={() =>
                                                                                            alert( "Support",
                                                                                            'This website was created to assist those who have \n'+
                                                                                            'lost a loved one and provides a list of duas they can perform. \n'+
                                                                                            'There is a cost involved in running this website, so if you can \n'+
                                                                                            'donate, then it will be appreciated. \n'+
                                                                                            'Please email mirzaam@gmail.com for anyy support you may have or queries on how to offer support\n'+
                                                                                            'Jazak Allah Khair')}
                                                                                            title="Support the website"
                                                                                            color="#5c656b"
                                                                                          />
                                                                                      </View>
                                                                                    ),
                                                                                  }}/>
           <Stack.Screen name="DeceasedPerson" component={DeceasedPerson} options={{
                                                                                    title: 'Add Person',
                                                                                    headerShown: false,
                                                                                    headerStyle: {
                                                                                      backgroundColor: '#dedede',

                                                                                    },

                                                                                    headerTitleStyle: {
                                                                                      fontWeight: 'bold',
                                                                                    },
                                                                                  }}/>


           <Stack.Screen name="DeceasedPersonDuas" component={DeceasedPersonDuas} options={{
                                                                                  title: 'Deceased Person Duas',
                                                                                  headerStyle: {
                                                                                    backgroundColor: '#dedede',

                                                                                  },
                                                                                  headerTitleStyle: {
                                                                                    fontWeight: 'bold',
                                                                                  },
                                                                                }}/>

           <Stack.Screen name="QuranMarhoom" component={QuranMarhoom} options={{

                                                                                 title: 'Quran',
                                                                                 headerStyle: {
                                                                                   backgroundColor: '#dedede',

                                                                                 },

                                                                                 headerTitleStyle: {
                                                                                   fontWeight: 'bold',
                                                                                 },
                                                                               }}/>

           <Stack.Screen name="DuasBeforeBurial" component={DuasBeforeBurial} options={{

                                                                                 title: 'Duas And Tasbeehs',
                                                                                 headerStyle: {
                                                                                   backgroundColor: '#dedede',

                                                                                 },

                                                                                 headerTitleStyle: {
                                                                                   fontWeight: 'bold',
                                                                                 },
                                                                               }}/>
         </Stack.Navigator>
   </NavigationContainer>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#ecf0f1',
  },
  container1: {
    top: 0,
    padding: 10,
    backgroundColor: '#ecf0f1',
  },
  customerInvisible: {
    display: 'none',
  },
  customerVisible: {
    color: 'gray',
    fontSize: 16,
    marginTop: 440
  },
  input: {
    marginTop: 20,
    color: 'black',
    fontSize: 16,
  },
  vendorInvisible: {
      display: 'none',
    },
  vendorVisible: {
    marginTop: 10,
    color: 'gray',
    fontSize: 16,
  },
  buttonInvisible: {
   display: 'none',
  },

  buttonVisible: {
    marginTop: 20,
    borderRadius:8,
    width: 200,
    borderColor: 'black',
    backgroundColor: 'green',
  },
    bgImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
     opacity: 0.4,
    }

});
