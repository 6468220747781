import React, { useState, useEffect} from 'react';
import { Platform, Alert, StyleSheet, Text,TextInput, View, TouchableOpacity,Dimensions  } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import DeceasedPersonDuas from './DeceasedPersonDuas';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import {Image, ImageBackground} from 'react-native';
const screenWidth = Math.round(Dimensions.get('window').width);
const SMALLWIDTH = 500;
//const nodeAddress = 'http://192.168.0.197:3000'
//const nodeAddress = 'https://34.89.25.113:3000'
//var nodeAddress = 'http://34.89.25.113:3000'
//const nodeWebAddress = 'https://34.89.25.113:8000'
//const nodeWebAddress = 'http://34.89.25.113:3000'
var nodeAddress = "https://3orbnk2lwqhj6ustsw76gbqmx40nhtbr.lambda-url.eu-west-2.on.aws"
const nodeWebAddress = "https://3orbnk2lwqhj6ustsw76gbqmx40nhtbr.lambda-url.eu-west-2.on.aws"

const defaultURI = 'https://drive.google.com/uc?export=view&id=1pJU_kPRy2xbEFurcA78bJUXitD0qI8M6';
export default function DuasForDepartedHome(props) {
  const [surname, setSurname] = useState('');
  const [firstNames, setFirstNames] = useState('');
  const [dateOfDeath, setDateOfDeath] = React.useState(null);
  const [dob, setDOB] = React.useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isFound, setIsFound] = React.useState(false);
  const [notFound, setNotFound] = React.useState('');
  const [ImageLink, setImageLink] = React.useState(null);

  var searchParams = null;
  var id = null
  let firstNameTemp = null
  let surnameTemp = null

  useEffect(() => {
    // Some initialization logic here
    if (Platform.OS==='web') {
       let url = window.location.href
       console.log ("url: "+url)
        let searchParams = new URL(url).searchParams;
        id = searchParams.get('id')
        console.log("id: "+id);
        firstNameTemp = searchParams.get('firstname');
        surnameTemp = searchParams.get('surname');
        console.log("firstName: "+firstNameTemp);
        console.log("surname: "+surnameTemp);

        if (id != null) {
            searchPerson();
        }
        else if (firstNameTemp !=null && surnameTemp != null) {

            setFirstNames(firstNameTemp);
            setSurname(surnameTemp);
            searchPerson();
        }


    }
  }, []);

  async function searchPerson() {
    console.log("searchPerson");
    setDateOfDeath(null);
    if (Platform.OS==='web') {
       nodeAddress = nodeWebAddress
    }

    //search database for any person entered already
    // if found then display name and duas recited and outstanding
    var response = null;
    console.log("firstName: "+firstNames);
    console.log("surname: "+surname );

    if (id != null) {
      response = await fetch(nodeAddress+'/PersonId?id='+ id);
    } else if (firstNameTemp !=null && surnameTemp != null) {
      response= await fetch(nodeAddress+'/Person?surname='+ surnameTemp+'&fullnames='+firstNameTemp);
    }
    else {
      response= await fetch(nodeAddress+'/Person?surname='+ surname+'&fullnames='+firstNames);
    }
      console.log(" Response status and text: "+response.status+": "+response.statusText);
    if (response.status >= 200 && response.status <= 299) {
      const userInfo = await response.json();
      console.log(userInfo);

      if (userInfo.length > 0) {
          console.log("Found: "+userInfo[0].surname)
          setSurname(userInfo[0].surname);
          setFirstNames(userInfo[0].fullnames);
          setDateOfDeath(userInfo[0].dateDied);
          setDOB(userInfo[0].dob);
          setUserDetails(userInfo);
          setIsFound(true);
          setNotFound('')
          setImageLink(userInfo[0].ImageLink);
      }
      else {
          console.log("Name not found: "+surname)
          setIsFound(false)
          setNotFound("Person not found, please check id, spelling or Add Person")
/*
          if (Platform.OS==='web') {
             alert("Person not found, please check id, spelling or Add Person")
          }
          else
             Alert.alert("Person not found, please check spelling or Add Person")
*/
      }

    } else {
      // Handle errors
      console.log("Error fetching: "+response.status+": "+response.statusText);
    }

  }
   function dateformat(date) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
     var day = date.getDate();

     var monthStr = monthNames[date.getMonth()];

     return day+" " +monthStr+" "+date.getFullYear();
   }
   function dateformatStr(dateStr) {

   var from = dateStr;
     console.log("formatDateStr from : "+from);
   var numbers = from.match(/\d+/g);

      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

     var monthStr = monthNames[numbers[1]-1];
     console.log("formatDateStr DateOfDeath : "+numbers);


     return numbers[2]+" " +monthStr+" "+numbers[0];
   }
   /*
   if (userDetails != null) {
          props.navigation.navigate('DeceasedPersonDuas',{ userDetails:userDetails })
          return null;
   }
   */
    function  onFirstNames(text) {
         setFirstNames(text)
         setDateOfDeath(null)
         setIsFound(false)
         setNotFound('')
      }
    function  onSurname(text) {
         setSurname(text)
         setDateOfDeath(null)
         setIsFound(false)
         setNotFound('')
    }
 function getImage() {
     console.log("ImageLink: " +ImageLink)
     return ImageLink;
 }

     return (

      <View style={styles.container}>

          <ImageBackground
            source={require('./assets/noor.png')}
            style={styles.bgImage}
            >
         <View style={{marginTop: 10, justifyContent: "center", alignItems: 'center' }}>
              <Image
              style={styles.blue}
              source={require('./assets/innaLilla.png') }
            />
         </View>

          <Text style={{fontSize: 16, color: 'white',opacity: 1, marginLeft: 10, marginTop: 20}}>
            {"As-Salam Alaikum. Condolences to your recently departed loved one \n"}
            {"If the person has been added before, please enter their names and press search, otherwise add the person."}
          </Text>

         <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-start', marginTop: 20   }}>
              <TextInput
                style={styles.input}
                placeholder="Enter first names"
                value={firstNames}
                onChangeText={(text) => onFirstNames(text)}
              />
              <TextInput
                style={styles.input}
                placeholder="Enter surname"
                value={surname}
                onChangeText={text => onSurname(text)}
              />

         </View>
         <View style={{ marginTop: 10 }}>
            <Image
              style={[!Boolean(surname==='Sheikh' && firstNames==="Abid" && isFound) ? styles.buttonInVisible: styles.personImage ]}
              source={{uri: getImage()}}
            />
        </View>
         <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-start', marginTop: 20   }}>
          <TouchableOpacity
            onPress={() => {searchPerson()}}
            style={[isFound ? styles.buttonInvisible:styles.buttonVisible]}>
            <Text style={{fontSize: 18, color: 'white',}}>{ 'Search Person'}</Text>
          </TouchableOpacity>


          <TouchableOpacity
             onPress={() => {props.navigation.navigate('DeceasedPerson',{ userDetails:userDetails })}}
            style={[isFound  ? styles.buttonInvisible:styles.buttonVisible]}>
             <Text style={{ fontSize: 18, color: 'white'}}>{'Add Person'}</Text>
           </TouchableOpacity>
         </View>




         <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-start', marginTop: 0, marginLeft: 10   }}>

              <Text style={{fontSize: 16, color: 'white',opacity: 1}}>
                {[isFound ? "Date Died: "+ dateformatStr(dateOfDeath): notFound]}
              </Text>
         </View>

         <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-start', marginTop: 0   }}>
              <TouchableOpacity
                onPress={() => {dateOfDeath !=null ? props.navigation.navigate('DeceasedPersonDuas',{ userDetails:userDetails }):""}}
                style={[isFound ? styles.buttonVisible:styles.buttonInvisible]}>
                <Text style={{ fontSize: 18, color: 'white'}}>{'Search Duas '}</Text>
              </TouchableOpacity>
         </View>

       </ImageBackground>
     </View>


     );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#ecf0f1',
  },
    personImage: {
    marginTop: 10,
    marginLeft: 10,
    borderRadius: 5,
    padding: 10,
    opacity: 0.8,
    width: screenWidth < SMALLWIDTH  ? 240:320,
    height: screenWidth < SMALLWIDTH  ? 150:200,
    },
    blue: {
      top: 0,
      opacity: 0.4,

      width: screenWidth < SMALLWIDTH  ? 275:375,
      height: screenWidth < SMALLWIDTH  ? 50:68,
    },
  input: {
    marginTop: 10,
    marginLeft: 10,
    borderWidth: 2,
    borderColor: 'black',
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 10,
    opacity: 0.7,
    height: 40,
    width: 160,
    fontSize: 15,
  },
    buttonVisible: {
      marginLeft: 10,
      opacity: 0.9,
      borderRadius:8,
      height: 34,
      width: 160,
      borderColor: 'black',
      justifyContent: "center",
      backgroundColor: '#5c656b',
      alignItems: "center",
    },
      buttonInvisible: {
       display: 'none',
      },

    bgImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",

    }
});


