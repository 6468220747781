import React, { useState, useEffect} from 'react';
import { Platform, Alert, Image, ImageBackground, FlatList, StyleSheet, Text,TextInput, View, TouchableOpacity, Dimensions } from 'react-native';

//const nodeAddress = 'http://192.168.0.197:3000'
//const nodeAddress = 'http://34.89.25.113:3000'
//const nodeAddress = 'http://192.168.0.197:3000'
//const nodeAddress = 'https://34.89.25.113:3000'
//var nodeAddress = 'http://34.89.25.113:3000'
//const nodeWebAddress = 'https://34.89.25.113:8000'
//const nodeWebAddress = 'http://34.89.25.113:3000'
var nodeAddress = "https://3orbnk2lwqhj6ustsw76gbqmx40nhtbr.lambda-url.eu-west-2.on.aws"
const nodeWebAddress = "https://3orbnk2lwqhj6ustsw76gbqmx40nhtbr.lambda-url.eu-west-2.on.aws"


const QURANCHAPTERCOUNT = 30;

const screenWidth = Math.round(Dimensions.get('window').width);
const SMALLWIDTH = 500;
var marginright = 0;

export default function QuranMarhoom(props) {
  let init = false;
  console.log("screenWidth: "+screenWidth)
  console.log("Quran props: " +JSON.stringify(props))
  console.log("Quran props init: " +init)

  const [reserveList, setReserveList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const userDetails = props.route.params.userDetails; //TODO - have to handle case where multiple user details returned.
  const [userId, setUserId] = useState(userDetails.userId);
  const [operation, setOperation] = React.useState(null);
  const [surname, setSurname] = useState(userDetails.surname);
  const [firstNames, setFirstNames] = useState(userDetails.fullnames);
  const [quranList, setQuranList] = React.useState(null);
  const [table, setTable] = useState(props.route.params.table);
  const [deathDate, setDeathDate] = useState(dateformat(new Date(userDetails.dateDied)));
  const [quranComplete, setQuranComplete] = React.useState(false);
  const [quranCompleteCt, setQuranCompleteCt] =  useState(0);

  function dateformat(date) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
     var day = date.getDate();

     var monthStr = monthNames[date.getMonth()];

     return day+" " +monthStr+" "+date.getFullYear();
   }
   useEffect(() => {
      console.log("UserInfo useEffect: entry");
      // get data if it exists before entering any new data
      if (init == false) {
         var tmpNameArray = [QURANCHAPTERCOUNT];
         for (var i=0; i < QURANCHAPTERCOUNT; i++) {
             tmpNameArray[i] = '';
         }
         console.log("initNameList : "+tmpNameArray)
         setNameList(tmpNameArray);
         fetchData();

      }
   },[]);//[] is required to only allow useEffect to be called once otherwise will always be called on render

   const loadDuas= async()=>{
       console.log('loadQuran...');
        if (Platform.OS==='web')
           nodeAddress = nodeWebAddress

       const request = nodeAddress+'/Quran';
       const operation = 'INSERT'


       fetch(request, {
             method: 'POST',
             headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json'},
             body: JSON.stringify({
               operation: operation,
               userId: userId,
               tableName: table
             }),
             timeout: 10000
       })
       .then(response => {
         console.log('done: '+response.insertId)
         fetchData();
       })
      .catch(err => {console.log("Error: "+err)});

      //init the Quran Completed Count
      initQuranCompletedCount();

   }

   const resetQuranNames= async()=>{
       console.log('resetQuranNames...'+quranList.length);
       const request = nodeAddress+'/Quran';

        fetch(request, {
             method: 'POST',
             headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json'},
             body: JSON.stringify({
               userId: userId,
               quranList: quranList,
               operation: 'RESET',
               reserve: reserveList,
               nameList: nameList,
               tableName: table,
             }),
             timeout: 10000
       })
       .then(response => {
         console.log('done: ')
         fetchData();
         initReserveList();

       })
       .catch(err => {console.log("Error: "+err)});



      //TODO also save the name of the person who has taken the dua against the userId and the dua and number taken

   }
   const updateDuas= async()=>{
        // Check anything to save before sending
        var save = false
        for (var i =0; i< nameList.length && save == false; i++) {
            if (nameList[i] !== null && nameList[i].trim() !== "") {
               save = true;
            }
        }
       console.log('updateDuas...'+reserveList.length);
       const request = nodeAddress+'/Quran';
       const operation = 'UPDATE'
       if (save == true) {
            fetch(request, {
                 method: 'POST',
                 headers: {
                   Accept: 'application/json',
                   'Content-Type': 'application/json'},
                 body: JSON.stringify({
                   userId: userId,
                   quranList: quranList,
                   operation: 'UPDATE',
                   reserve: reserveList,
                   nameList: nameList,
                   tableName: table,
                 }),
                 timeout: 10000
            })
            .then(response => {
              console.log('done: ')
              fetchData();
            })
            .catch(err => {console.log("Error: "+err)});
       }
       else {
          console.log ("Nothing to save, please enter a name")
       }

      //TODO also save the name of the person who has taken the dua against the userId and the dua and number taken

   }

  const initQuranCompletedCount= async()=>{
       console.log('initQuranCompletedCount...');

       setQuranCompleteCt(0);
       const request = nodeAddress+'/CompletedQuran';

      // const operation = 'UPDATE'
        fetch(request, {
             method: 'POST',
             headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json'},
             body: JSON.stringify({
               userId: userId,
               operation: 'INSERT',
               completedQuranCt: 0,
               tableName: 'CompletedQuran',
             }),
             timeout: 10000
       })
       .then(response => {
         console.log('initQuranCompletedCount done: ')
         fetchData();
       })
       .catch(err => {console.log("Error: "+err)});

  }

  const updateQuranCompleted= async()=>{
       console.log('updateQuranCompleted...');
       const request = nodeAddress+'/CompletedQuran';
      // const operation = 'UPDATE'

        var tmpQuranCompleteCt = quranCompleteCt+1;
        setQuranCompleteCt(tmpQuranCompleteCt);
        fetch(request, {
             method: 'POST',
             headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json'},
             body: JSON.stringify({
               userId: userId,
               operation: 'UPDATE',
               completedQuranCt: 1,
               tableName: 'CompletedQuran',
             }),
             timeout: 10000
       })
       .then(response => {
         console.log('done: ')
         fetchData();

       })
       .catch(err => {console.log("Error: "+err)});

  }

   const initReserveList = () => {
      console.log("initReserveList")
      var tmpReserveArray = [QURANCHAPTERCOUNT];
      var tmpNameArray = [QURANCHAPTERCOUNT];
      for (var i=0; i < QURANCHAPTERCOUNT; i++) {
          console.log("initReserveList i: "+i)
          tmpReserveArray[i] = 0;
          tmpNameArray[i] = '';
      }
      console.log("initReserveList : "+tmpReserveArray)
      console.log("initNameList : "+tmpNameArray)
      setReserveList(tmpReserveArray);
      setNameList(tmpNameArray);

   }

   const fetchData= async()=>{
       console.log("fetchData: entry: "+userId);
       init=true;
        if (Platform.OS==='web')
           nodeAddress = nodeWebAddress

       const request = nodeAddress+'/Duas';

       console.log("fetchData request: "+request+'?userId='+ userId+'&'+'tableName='+table);
       const response = await fetch(request+'?userId='+ userId+'&'+'tableName='+table);
       if (response.status >= 200 && response.status <= 299) {
         const userInfo = await response.json();
         console.log("fetchData userInfo left: "+JSON.stringify(userInfo));
         if (userInfo.length > 0) {
             console.log("fetchData userInfo length: "+userInfo.length);
             setOperation("UPDATE")
             setQuranList(userInfo);
             initReserveList();

            // get qurans completed for marhoom
            fetchCompletedQuran()

           // check if current Quran has been completed

           var isQuranComplete = true;
           // do this if you only want the button to show when all chapters are done
           for (var i=0;i<userInfo.length;i++) {
                if (userInfo[i].name == null || userInfo[i].name === "" ) {
                    isQuranComplete = false;
                    break;
                }
                console.log("isQuran Complete: "+isQuranComplete+ "idx: "+i);
           }

           setQuranComplete(isQuranComplete);

         }
         else {
             console.log("No records found fetching data, userId: "+userId)
             setOperation("INSERT")
             loadDuas()
         }

       } else {
         // Handle errors
         console.log(response.status, response.statusText);
       }



     }
     const fetchCompletedQuran= async()=>{
       console.log("fetchCompletedQuran: entry: "+userId);
       init=true;
        if (Platform.OS==='web')
           nodeAddress = nodeWebAddress

       const request = nodeAddress+'/Duas';

       const response = await fetch(request+'?userId='+ userId+'&'+'tableName=CompletedQuran');
       if (response.status >= 200 && response.status <= 299) {
         const userInfo = await response.json();
         if (userInfo.length > 0) {
             console.log("fetchCompletedQuran userInfo length: "+userInfo.length);
             setOperation("UPDATE")
             setQuranCompleteCt(userInfo[0].quransCompleted);

             console.log("results: "+JSON.stringify(userInfo[0]))
         }
         else {
             console.log("No records found fetching data, userId: "+userId)
             setOperation("INSERT")
             initQuranCompletedCount()
         }

       } else {
         // Handle errors
         console.log(response.status, response.statusText);
       }



     }

    function reserveItem(item) {
      console.log("reserveItem: "+item.surah);
      alert("reserveItem: "+item.surah)
      //dialog to reserve

    }
    const handleReserveUpdate = (item, index) => {
      console.log("handleReserveUpdate: "+item+": "+index)
//      console.log("handleReserveUpdate: "+newReserveList)
      if (/^-?\d+$/.test(item) || item === ''|| item ==='-') {
       if (item ==='' || item ==='-')
         item=0;
       let newReserveList = [...reserveList] ;
        newReserveList[index] = parseInt(item,10);
       console.log("handleReserveUpdate, name before: "+nameList[index])
        setReserveList(newReserveList);
        console.log("handleReserveUpdate, name after: "+nameList[index])
      }
    }
    const handleNameUpdate = (item, index) => {
      console.log("handleNameUpdate: "+item+": "+index)
       let newNameList = [...nameList] ;
        newNameList[index] = item;
       console.log("handleNameUpdate, newname before: "+newNameList)
       console.log("handleNameUpdate, name before: "+nameList)
        setNameList(newNameList);
        console.log("handleNameUpdate, name after: "+nameList)

    }

    const renderItem = ({ item, index }) => (
    quranList && (
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center'}}>
            {<Text style={styles.qurantextLabel}> {quranList[index].surah} </Text>}
            <Text style={styles.subtextTotalLabel}> {quranList[index].totalCount} </Text>
            <Text style={styles.subtextNameLabel}> {quranList[index].name} </Text>

            <TextInput
                style={styles.inputText}
                onChangeText={text => handleNameUpdate(text, index)}
                value = {item.toString()}
                maxLength = {12}
            />
           </View>
    )
  );
   console.log('nameList', nameList);
     var platform
     if (Platform.OS === 'android' || Platform.OS === 'ios') {
       marginright = 0
     }
     else {
       marginright = 200;
     }
     console.log("marginright : "+marginright)
     console.log("marginright : "+marginright)

     let searchParams = new URL(window.location.href).searchParams;

    return (


   <View style={styles.container}>
       <ImageBackground
          source={require('./assets/noor.png')}
          style={styles.bgImage}>
          <View style={{flexDirection: 'row',justifyContent: 'space-between'}}>
            <Text
              style={styles.textLabel}>
              {"Duas for "+firstNames+" "+surname}
            </Text>
            {searchParams.get('admin') === 'true' && <TouchableOpacity
              onPress={() => resetQuranNames()}
              style={styles.resetButton}>
              <Text style={{ textAlign: 'center', fontSize: 16, color: 'white',width: 120}}>Reset Quran </Text>
            </TouchableOpacity>}
          </View>

          <Text
            style={styles.textLabel}>
            {"Died on: "+deathDate}
          </Text>
          <Text
            style={styles.textLabel}>
            {"Qurans Completed: "+quranCompleteCt}
          </Text>

            <TouchableOpacity
              onPress={() => updateDuas()}
              style={styles.buttonVisible}>
              <Text style={{ fontSize: 17, color: 'white'}}>Reserve Chapters</Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => updateQuranCompleted()}
              style={[quranComplete ? styles.buttonVisible:styles.buttonInvisible]}>
              <Text style={{  fontSize: 17, color: 'white'}}> Completed Quran</Text>
            </TouchableOpacity>


          <View style={{flexDirection: 'row'}}>
              <Text
                 style={styles.qurantextHeadingLabel}>
                 {"Chapter"}
              </Text>
              <Text
                 style={styles.subtextTotalHeadingLabel}>
                 {"Total"}
              </Text>
              <Text
                 style={styles.subtextNameHeadingLabel}>
                 {"Name"}
              </Text>
              <Text
                 style={styles.subtextEndHeadingLabel}>
                 {"Enter Name"}
              </Text>
           </View>
          <View style={{flex: 5, flexDirection: 'row'}}>
              <FlatList data={nameList}
                            renderItem={renderItem}
                            keyExtractor={(item, index) => index.toString()}
              />
          </View>
      </ImageBackground>
   </View>
 );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 5,
    backgroundColor: '#ecf0f1',
  },

  inputText: {
      flex: 3,
      marginTop: 4,
      opacity: 0.9,
      marginRight: screenWidth < SMALLWIDTH  ? 0 : 80,
      color: 'black',
      width: 100,
      height: 25,
      borderWidth: 2,
      borderRadius: 5,
      borderColor: 'black',
      fontWeight: 'bold',
      backgroundColor: 'white',
      fontSize: 14,
  },
  textLabel: {
    padding: 10,
    color: 'white',
    fontSize: 18,
  },
  qurantextLabel: {
    flex: screenWidth < SMALLWIDTH ? 3 : 4,
    marginLeft: 5,
    marginTop: 5,
    fontWeight: 'bold',
    backgroundColor: 'lightgray',
    opacity: 0.6,
    borderColor: 'black',
    color: 'black',
    fontSize: 14,
  },
  subtextNameLabel: {
    flex: 3,
    marginTop: 5,
    fontWeight: 'bold',
    backgroundColor: 'lightgray',
    opacity: 0.6,
    borderColor: 'black',
    color: 'black',
    fontSize: 14,
  },
  subtextTotalLabel: {
    flex: 2,
    marginTop: 5,
    fontWeight: 'bold',
    backgroundColor: 'lightgray',
    opacity: 0.6,
    borderColor: 'black',
    color: 'black',
    fontSize: 14,
  },
    qurantextHeadingLabel: {
      flex: screenWidth < SMALLWIDTH  ? 3 : 4,
      marginTop: 10,
      marginLeft: 5,
      color: 'black',
      height: 25,
      backgroundColor: '#8dc2e0',
      fontWeight: 'bold',
      fontSize: 14,
    },
    subtextTotalHeadingLabel: {
      flex: 2,
      marginTop: 10,
      color: 'black',
      height: 25,
      backgroundColor: '#8dc2e0',
      fontWeight: 'bold',
      fontSize: 14,
    },
    subtextNameHeadingLabel: {
      flex: 3,
      marginTop: 10,
      color: 'black',
      height: 25,
      backgroundColor: '#8dc2e0',
      fontWeight: 'bold',
      fontSize: 14,
    },
    subtextEndHeadingLabel: {
      flex: 3,
      marginTop: 10,
      marginRight: screenWidth < SMALLWIDTH  ? 0 : 80,
      color: 'black',
      height: 25,
      backgroundColor: '#8dc2e0',
      fontWeight: 'bold',
      fontSize: 14,
    },

    bgImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",

    },
    resetButton: {
      marginTop: 10,
      marginRight: 10,
      opacity: 0.9,
      borderRadius:8,
      height: 20,
      width: 120,
      borderColor: 'black',
      justifyContent: "center",
      backgroundColor: '#5c656b',
      alignItems: "center",
    },
    buttonVisible: {
      marginLeft: 10,
      opacity: 0.9,
      borderRadius:8,
      height: 34,
      width: 160,
      borderColor: 'black',
      justifyContent: "center",
      backgroundColor: '#5c656b',
      alignItems: "center",
    },
    buttonInvisible: {
       display: 'none',
    }

});

