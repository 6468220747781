import React, { useState, useEffect} from 'react';
import { Alert, Image,  FlatList, StyleSheet, Text,TextInput, View, TouchableOpacity } from 'react-native';
import DuasBeforeBurial from './DuasBeforeBurial';
import QuranMahroom from './QuranMarhoom';
import {ImageBackground} from 'react-native';

  //let reserveList = Array(DuasBeforeBurialCount).fill(0);
export default function DeceasedPersonDuas(props) {

  const [reserveList, setReserveList] = useState([]);
  var userDetails = props.route.params.userDetails[0]; //TODO - have to handle case where multiple user details returned.
  console.log('userDetails1: '+JSON.stringify(userDetails))
  if (userDetails == null) {
     userDetails = props.route.params.userDetails; // doing this as DeceasedPerson not returning an array
  }
  console.log('userDetails2: '+JSON.stringify(userDetails))
  const [userId, setUserId] = useState(userDetails.userId);
  const [surname, setSurname] = useState(userDetails.surname);
  const [firstNames, setFirstNames] = useState(userDetails.fullnames);
  const [deathDate, setDeathDate] = useState(dateformat(new Date(userDetails.dateDied)));
  const [dob, setDob] = useState(null);

  function dateformat(date) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
     var day = date.getDate();

     var monthStr = monthNames[date.getMonth()];

     return day+" " +monthStr+" "+date.getFullYear();
   }
    return (
   <View style={styles.container}>
       <ImageBackground
            source={require('./assets/noor.png')}
            style={styles.bgImage}>

     <View style={{flex: 1, flexDirection: 'column', justifyContent: 'flex-start', marginTop: 20   }}>


          <Text
            style={styles.textLabel}>
            {"Duas for "+firstNames+" "+surname}
          </Text>
          <Text
            style={styles.textLabel}>
            {"Died on: "+deathDate}
          </Text>

          <TouchableOpacity
              onPress={() => {props.navigation.navigate('DuasBeforeBurial',{ userDetails:userDetails, table:'DuasBeforeBurial' })}}
              style={{borderRadius:8, borderColor: 'black', backgroundColor: 'gray', margin:10, width: 200 }}>
              <Text style={{ textAlign: 'center', fontSize: 18, color: 'white',height: 38,width: 200}}>Duas Before Burial</Text>
          </TouchableOpacity>
          <TouchableOpacity
              onPress={() => {props.navigation.navigate('DuasBeforeBurial',{ userDetails:userDetails, table:'DuasBeforeChehlum' })}}
              style={{borderRadius:8, borderColor: 'black', backgroundColor: 'gray', margin:10, width: 200 }}>
              <Text style={{ textAlign: 'center', fontSize: 18, color: 'white',height: 38,width: 200}}>Duas Before Chehlum</Text>
          </TouchableOpacity>
          <TouchableOpacity
              onPress={() => {props.navigation.navigate('DuasBeforeBurial',{ userDetails:userDetails, table:'DuasOnChehlum' })}}
              style={{borderRadius:8, borderColor: 'black', backgroundColor: 'gray', margin:10, width: 200 }}>
              <Text style={{ textAlign: 'center', fontSize: 18, color: 'white',height: 38,width: 200}}>Duas On Chehlum</Text>
          </TouchableOpacity>
          <TouchableOpacity
              onPress={() => {props.navigation.navigate('QuranMarhoom',{ userDetails:userDetails, table:'Quran' })}}
              style={{borderRadius:8, borderColor: 'black', backgroundColor: 'gray', margin:10, width: 200 }}>
              <Text style={{ textAlign: 'center', fontSize: 18, color: 'white',height: 38,width: 200}}>Quran</Text>
          </TouchableOpacity>
        </View>
        </ImageBackground>
   </View>
 );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 5,
    backgroundColor: '#ecf0f1',
  },
  input: {
  flex: 1,
    marginTop: 10,
    color: 'black',
    width: 5,
    height: 25,
    backgroundColor: 'gray',
    fontSize: 14,
  },
  textLabel: {
    padding: 10,
    color: 'white',
    fontSize: 18,
  },
  duatextLabel: {
    flex: 3,
    marginTop: 10,
    color: 'black',
    fontSize: 14,
  },
  subtextLabel: {
    flex: 1,
    marginTop: 10,
    color: 'black',
    fontSize: 14,
  },
    duatextHeadingLabel: {
      flex: 3,
      marginTop: 10,
      color: 'black',
      height: 25,
      backgroundColor: '#8dc2e0',
      fontSize: 14,
    },
    subtextHeadingLabel: {
      flex: 1,
      marginTop: 10,
      color: 'black',
      height: 25,
      backgroundColor: '#8dc2e0',
      fontSize: 14,
    },

    bgImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",

    }
});