import React, { useState, useEffect} from 'react';
import { Platform, Alert, Image,  StyleSheet, Text,TextInput, View, TouchableOpacity } from 'react-native';
import {ImageBackground} from 'react-native';


//const nodeAddress = 'http://192.168.0.197:3000'
//const nodeAddress = 'http://34.89.25.113:3000'
var nodeAddress = "https://3orbnk2lwqhj6ustsw76gbqmx40nhtbr.lambda-url.eu-west-2.on.aws"

//const nodeWebAddress = 'https://34.89.25.113:8000'
//const nodeWebAddress = 'http://34.89.25.113:3000'
const nodeWebAddress  = "https://3orbnk2lwqhj6ustsw76gbqmx40nhtbr.lambda-url.eu-west-2.on.aws"

export default function DeceasedPerson(props) {
  const [surname, setSurname] = useState('');
  const [firstNames, setFirstNames] = useState('');
  const [deathDate, setDeathDate] = useState(null);
  const [published, setPublished] = useState(false);
  const [dob, setDob] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  var date = null;


  async function postPersonInfo() {

        var dateDeath = formatDateOfDeath()
        console.log("postPersonInfo: entry: "+dateDeath);
        if (Platform.OS==='web')
           nodeAddress = nodeWebAddress

        const request = nodeAddress+'/Person';
 //       const request = 'http://34.89.25.113:3000/User';
          const response = await fetch(request, {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'},
                      body: JSON.stringify({
                        operation: 'INSERT',
                        surname: surname,
                        firstNames: firstNames,
                        deathDate: deathDate,
                        dob: dob,
                        }),
                      timeout: 10000
                    })
                    if (response.status >= 200 && response.status <= 299) {
                        const userInfo = await response.json();
                        console.log("Found: "+userInfo)
                        console.log('done: '+userInfo.insertId)

                      let obj = [
                           {
                             "userId": userInfo.insertId,
                             "surname": surname,
                             "fullnames": firstNames,
                             "dateDied": deathDate,
                             "dob": dob,
                           },
                       ]
                        console.log('done obj: '+JSON.stringify(obj[0]))
                        setUserDetails(obj);
                        setPublished(true);
                        Alert.alert("Published User",
                                    "summary: "+surname);
                        //setUserDetails(userInfo);
                    }
                    else  {
                        console.log("Error: "+err)
                    };
        console.log('fetching...');


   }

   function formatDateOfDeath() {

       var from = deathDate;
       var numbers = from.match(/\d+/g);
       date = new Date(numbers[2], numbers[1]-1, numbers[0]);
       console.log("formatDateOfDeath: "+numbers);

       console.log("formatDateOfDeath: "+date);
       return date;

   }
   return (

     <View style={styles.container}>
      <ImageBackground
           source={require('./assets/noor.png')}
           style={styles.bgImage}>

         <Text style={{fontSize: 16, color: 'black',opacity: 1, marginLeft: 10, marginTop: 30}}>
           {"Please enter the details of the deceased person (date of birth is optional)"}
         </Text>
     <View style={{flex: 1, flexDirection: 'column', justifyContent: 'flex-start', marginTop: 20   }}>

      <TextInput
        style={styles.input}
        placeholder="*Enter Persons first names"
        onChangeText={firstNames => setFirstNames(firstNames)}
        defaultValue={firstNames}
      />

      <TextInput
        style={styles.input}
        placeholder="*Enter Persons surname"
        onChangeText={surname => setSurname(surname)}
        defaultValue={surname}
      />

      <TextInput
        style={styles.input}
        keyboardType = 'default'
        placeholder="Enter Date Died (yyyy-mm-dd)"
        onChangeText={deathDate => setDeathDate(deathDate)}
        defaultValue={deathDate}
      />
      <TextInput
        style={styles.input}
        keyboardType = 'default'
        placeholder="Enter Date of Birth (yyyy-mm-dd)"
        onChangeText={dob => setDob(dob)}
        defaultValue={dob}
      />


      <TouchableOpacity
        onPress={() => postPersonInfo()}
        disabled={!Boolean(surname && firstNames)}
        style={[!Boolean(surname && firstNames) ? styles.buttonSemiVisible: styles.buttonVisible ]}>
        <Text style={{ fontSize: 20, color: 'white'}}>Store Details</Text>
      </TouchableOpacity>
      <Text style={{fontSize: 18, color: 'black',opacity: 1, marginLeft: 10, marginTop: 30}}>
         {published ? "Details Stored, share link below:":""}
      </Text>
      <Text style={{fontSize: 18, color: 'black',opacity: 1, marginLeft: 10, marginTop: 30}}>
         {published ? "http://duasfordeparted.com/?firstname="+firstNames+"&surname="+surname:""}
      </Text>
      <TouchableOpacity
          onPress={() => {userDetails !=null ? props.navigation.navigate('DeceasedPersonDuas',{ userDetails:userDetails }):""}}
          style={[userDetails ==null ? styles.buttonInvisible:styles.buttonVisible]}>
          <Text style={{ fontSize: 20, color: 'white'}}>{'Search Duas '}</Text>
      </TouchableOpacity>


     </View>
     </ImageBackground>

    </View>
   );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#ecf0f1',
  },
  input: {
      marginTop: 10,
      marginLeft: 20,
      borderWidth: 2,
      borderColor: 'black',
      backgroundColor: 'white',
      borderRadius: 5,
      padding: 10,
      opacity: 0.8,
      height: 40,
      width: 280,
      fontSize: 16,
    },
    bgImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",

    },
    buttonVisible: {
      marginTop: 10,
      marginLeft: 20,
      opacity: 0.9,
      borderRadius:8,
      height: 34,
      width: 160,
      borderColor: 'black',
      justifyContent: "center",
      backgroundColor: '#5c656b',
      alignItems: "center",
    },
    buttonSemiVisible: {
          marginTop: 10,
          marginLeft: 20,
          opacity: 0.4,
          borderRadius:8,
          height: 34,
          width: 160,
          borderColor: 'black',
          justifyContent: "center",
          backgroundColor: '#5c656b',
          alignItems: "center",
    },
    buttonInvisible: {
       display: 'none',
      },
});