import React, { useState, useEffect} from 'react';
import { Platform, Alert, Image, ImageBackground, FlatList, StyleSheet, Text,TextInput, View, TouchableOpacity, Dimensions } from 'react-native';

//const nodeAddress = 'http://192.168.0.197:3000'
//const nodeAddress = 'http://34.89.25.113:3000'
//var nodeAddress = 'http://34.89.25.113:3000'
var nodeAddress = "https://3orbnk2lwqhj6ustsw76gbqmx40nhtbr.lambda-url.eu-west-2.on.aws"

//const nodeWebAddress = 'https://34.89.25.113:8000'
//const nodeWebAddress = 'http://34.89.25.113:3000'
const nodeWebAddress  = "https://3orbnk2lwqhj6ustsw76gbqmx40nhtbr.lambda-url.eu-west-2.on.aws"

const DuasBeforeBurialCount = 24;
const screenWidth = Math.round(Dimensions.get('window').width);
const SMALLWIDTH = 500;

export default function DuasBeforeBurial(props) {
  let init = false;

  console.log("DuasBeforeBurial props: " +JSON.stringify(props))
  console.log("DuasBeforeBurial props init: " +init)

  const [reserveList, setReserveList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const userDetails = props.route.params.userDetails; //TODO - have to handle case where multiple user details returned.
  const [userId, setUserId] = useState(userDetails.userId);
  const [operation, setOperation] = React.useState(null);
  const [surname, setSurname] = useState(userDetails.surname);
  const [firstNames, setFirstNames] = useState(userDetails.fullnames);
  const [duasBeforeBurial, setDuasBeforeBurial] = React.useState(null);
  const [table, setTable] = useState(props.route.params.table);
  const [deathDate, setDeathDate] = useState(dateformat(new Date(userDetails.dateDied)));
  function dateformat(date) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
     var day = date.getDate();

     var monthStr = monthNames[date.getMonth()];

     return day+" " +monthStr+" "+date.getFullYear();
   }
   useEffect(() => {
      console.log("UserInfo useEffect: entry");
      // get data if it exists before entering any new data
      if (init == false)
         fetchData();
   },[]);//[] is required to only allow useEffect to be called once otherwise will always be called on render

   const loadDuas= async()=>{
       console.log('loadDuas...');
        if (Platform.OS==='web')
           nodeAddress = nodeWebAddress

       const request = nodeAddress+'/Duas';
       const operation = 'INSERT'


       fetch(request, {
             method: 'POST',
             headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json'},
             body: JSON.stringify({
               operation: operation,
               userId: userId,
               tableName: table
             }),
             timeout: 10000
       })
       .then(response => {
         console.log('done: '+response.insertId)
         fetchData();
       })
      .catch(err => {console.log("Error: "+err)});

   }
   const updateDuas= async()=>{
       console.log('updateDuas...'+reserveList.length);
       const request = nodeAddress+'/Duas';
       const operation = 'UPDATE'


       for (var i=0; i < reserveList.length; i++) {
         if(reserveList[i] != 0) {
           //var duasLeft = duasBeforeBurial[i].totalLeft-reserveList[i];
            fetch(request, {
                 method: 'POST',
                 headers: {
                   Accept: 'application/json',
                   'Content-Type': 'application/json'},
                 body: JSON.stringify({
                   operation: operation,
                   userId: userId,
                   duaId: duasBeforeBurial[i].duaId,
                   reserve: reserveList[i],
                   name: nameList[i],
                   tableName: table,
                 }),
                 timeout: 10000
           })
           .then(response => {
             console.log('done: ')
             fetchData();

           })
           .catch(err => {console.log("Error: "+err)});
         }
       }

      //TODO also save the name of the person who has taken the dua against the userId and the dua and number taken

   }

   const initReserveList = (userInfo) => {
      console.log("initReserveList userInfo: "+userInfo)
      var tmpReserveArray = [DuasBeforeBurialCount];
      var tmpNameArray = [DuasBeforeBurialCount];
      for (var i=0; i < userInfo.length; i++) {
          console.log("initReserveList i: "+i)
          console.log("initReserveList userInfo left: "+ userInfo[i].totalLeft)
          tmpReserveArray[i] = 0;
          tmpNameArray[i] = '';
      }
      setReserveList(tmpReserveArray);
      setNameList(tmpNameArray);

     console.log("initReserveList : "+reserveList)
     console.log("initNameList : "+nameList)
   }

   const fetchData= async()=>{
       console.log("fetchData: entry: "+userId);
       init=true;
        if (Platform.OS==='web')
           nodeAddress = nodeWebAddress

       const request = nodeAddress+'/Duas';

       const response = await fetch(request+'?userId='+ userId+'&'+'tableName='+table);
       if (response.status >= 200 && response.status <= 299) {
         const userInfo = await response.json();
         console.log("fetchData userInfo left: "+userInfo);
         if (userInfo.length > 0) {
             console.log("fetchData userInfo length: "+userInfo.length);
             setOperation("UPDATE")
             setDuasBeforeBurial(userInfo);
             initReserveList(userInfo);
           //  updateDuas()
             console.log("results: "+userInfo[0])
         }
         else {
             console.log("No records found fetching data, userId: "+userId)
             setOperation("INSERT")
             loadDuas()
         }

       } else {
         // Handle errors
         console.log(response.status, response.statusText);
       }

     }

    function reserveItem(item) {
      console.log("reserveItem: "+item.surah);
      alert("reserveItem: "+item.surah)
      //dialog to reserve

    }
    const handleReserveUpdate = (item, index) => {
      console.log("handleReserveUpdate: "+item+": "+index)
//      console.log("handleReserveUpdate: "+newReserveList)
      if (/^-?\d+$/.test(item) || item === ''|| item ==='-') {
       if (item ==='' || item ==='-')
         item=0;
       let newReserveList = [...reserveList] ;
        newReserveList[index] = parseInt(item,10);
       console.log("handleReserveUpdate, name before: "+nameList[index])
        setReserveList(newReserveList);
        console.log("handleReserveUpdate, name after: "+nameList[index])
      }
    }
    const handleNameUpdate = (item, index) => {
      console.log("handleNameUpdate: "+item+": "+index)
       let newNameList = [...nameList] ;
        newNameList[index] = item;
        setNameList(newNameList);
    }

    const renderItem = ({ item, index }) => (
    duasBeforeBurial && (
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center'}}>
            {<Text style={styles.duatextLabel}> {duasBeforeBurial[index].surah} </Text>}
            <Text style={styles.subtextLabel}> {duasBeforeBurial[index].totalCount} </Text>
            <Text style={styles.subtextLabel}> {duasBeforeBurial[index].totalLeft} </Text>
            <TextInput
                style={styles.input}
                placeholder = "Enter how many"
                onChangeText={e => handleReserveUpdate(e, index)}
                value = {item.toString()}
                maxLength = {4}
            />
        </View>
    )
  );
   console.log('reserveList', reserveList);

    return (


   <View style={styles.container}>
       <ImageBackground
            source={require('./assets/noor.png')}
            style={styles.bgImage}>
          <Text
            style={styles.textLabel}>
            {"Duas for "+firstNames+" "+surname}
          </Text>
          <Text
            style={styles.textLabel}>
            {"Died on: "+deathDate}
          </Text>
          <Text
            style={styles.textLabel}>
            {table}
          </Text>

          <TouchableOpacity
              onPress={() => updateDuas()}
              style={{borderRadius:8, borderColor: 'black', backgroundColor: 'gray', margin:10, width: 200 }}>
              <Text style={{ textAlign: 'center', fontSize: 18, color: 'white',height:30,width: 200}}>Store Reserved Duas</Text>
          </TouchableOpacity>

          <View style={{flexDirection: 'row'}}>
              <Text
                 style={styles.duatextHeadingLabel}>
                 {"Surah/Dua"}
              </Text>
              <Text
                 style={styles.subtextHeadingLabel}>
                 {"Total"}
              </Text>
              <Text
                 style={styles.subtextHeadingLabel}>
                 {"To Do"}
              </Text>
              <Text
                 style={styles.subtextEndHeadingLabel}>
                 {"Reserve"}
              </Text>
              <Text
                 style={[table==='Quran'?{display: 'none'}:{display: 'none'}]}>
                 {"Name"}
              </Text>
          </View>
          <View style={{flex: 5, flexDirection: 'row'}}>
              <FlatList data={reserveList}
                            renderItem={renderItem}
                            keyExtractor={(item, index) => index.toString()}
              />
          </View>
      </ImageBackground>
   </View>
 );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 5,
    backgroundColor: '#ecf0f1',
  },
  input: {
    flex: screenWidth < SMALLWIDTH ? 2 : 3,
    marginTop: 5,
    opacity: 0.9,
    marginRight: screenWidth < SMALLWIDTH  ? 0 : 80,
    color: 'black',
    width: 5,
    height: 28,
    paddingLeft: 3,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: 'black',
    fontWeight: 'bold',
    backgroundColor: 'white',
    fontSize: 14,
  },
  textLabel: {
    padding: 10,
    color: 'white',
    fontSize: 18,
  },
  duatextLabel: {
    flex: 8,
    marginLeft: 5,
    marginTop: 5,
    fontWeight: 'bold',
    backgroundColor: 'lightgray',
    opacity: 0.6,
    borderColor: 'black',
    color: 'black',
    fontSize: 14,
  },
  subtextLabel: {
    flex: 2,
    marginTop: 5,
    fontWeight: 'bold',
    backgroundColor: 'lightgray',
    opacity: 0.6,
    borderColor: 'black',
    color: 'black',
    fontSize: 14,
  },

    duatextHeadingLabel: {
      flex: 8,
      marginTop: 10,
      marginLeft: 5,
      color: 'black',
      height: 25,
      backgroundColor: '#8dc2e0',
      fontWeight: 'bold',
      fontSize: 14,
    },
    subtextHeadingLabel: {
      flex: 2,
      marginTop: 10,
      color: 'black',
      height: 25,
      backgroundColor: '#8dc2e0',
      fontWeight: 'bold',
      fontSize: 14,
    },
    subtextEndHeadingLabel: {
      flex: screenWidth < SMALLWIDTH ? 2 : 3,
      marginTop: 10,
      marginRight: screenWidth < SMALLWIDTH  ? 0 : 80,
      color: 'black',
      height: 25,
      backgroundColor: '#8dc2e0',
      fontWeight: 'bold',
      fontSize: 14,
    },

    bgImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",

    }

});

